import React from "react"


const InnerPageHero = (props) => (

    <section className="hero--inner-page" style={{ backgroundImage: 'url(' + props.heroImg + ')' }}>
        <div className="container">
            <div className="hero-text">
                <h1 className={`hero-text--title title ${props.color}`} >{props.title}</h1>
                <p className="hero-text--paragraph-text paragraph-text">{props.subtitle}</p>
            </div>
        </div>

    </section>
)

export default InnerPageHero;