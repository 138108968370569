import { Link } from "gatsby"
import React from "react"

import {Button} from "./button"

const Contact = () => (
  <section className="contact" id="contact">
    <div className="contact-info">
      <p className="eyebrow-text">CONTACT US</p>
      <h3>The future of social services is here</h3>
      <p className="paragraph-text">
        Find out what we’re doing to stay on the cutting edge of social service
        innovation with a free demo.
      </p>
      <Button buttonText="Request Free Demo" />
      <p className="body-text">or <Link to="/join" className="join-link">Join The Network</Link></p>
    </div>
  </section>
)

export default Contact
