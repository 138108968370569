import React from "react";
import { graphql } from 'gatsby';
import styles from './news.module.scss';

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/contact";
import InnerPageHero from "../components/innerPageHero";
import {NewsCard, getLongDate} from "../components/cards";
import heroImg from "../images/insights-hero.jpg";


const NewsPage = ({ data }) => (
  <Layout>
    <SEO title="CareAdvisors | Social Care Automation for Community Health" />
    <InnerPageHero
            title="News"
            subtitle="Stay informed with the CareAdvisors Newsroom"
            heroImg={heroImg}
            color="green"
    />
    <div className="container">
    <div className={styles.news}>
    {
      data.allWpNewsPost.nodes
        .sort(
          (left, right) => (Date.parse(right.date) - Date.parse(left.date))
        )
        .map((post) => {
        return (
          <NewsCard
          title={post.title}
          image={post.featuredImage ? post.featuredImage.node : null}
          content={post.content}
          date={`${getLongDate(post.date).month} ${getLongDate(post.date).date}, ${getLongDate(post.date).year}`}
          author={post.newsData.author}
          typeOfPost={post.newsData.typeOfPost}
          buttonLink={getButtonLink(post)}
          newTab={post.newsData.typeOfPost !== 'internal'}
        />
        )
      })
    }
  </div>
    </div>

    <Contact />

  </Layout>
)

const getButtonLink = (data) => {
  if(data.newsData.typeOfPost === 'external') {
    return data.newsData.externalLink;
  } else if (data.newsData.typeOfPost === 'internal') {
    return '/news/' + data.databaseId;
  } else if (data.newsData.typeOfPost === 'pdf') {
    return data.newsData.pdfUpload.mediaItemUrl;
  }
}

export const data = graphql`
query NewsQuery {
    allWpNewsPost {
      nodes {
        id
        databaseId
        title
        content
        date
        newsData {
          author
          typeOfPost
          externalLink
          pdfUpload {
              mediaItemUrl
          }
        }
        featuredImage {
            node {
              srcSet
              sizes
            }
        }
      }
    }
  }  
`

export default NewsPage
