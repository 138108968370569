import { Link } from "gatsby"
import React from "react"
import sanitizeHtml from 'sanitize-html';

import styles from './cards.module.scss';

import { CmsButton } from "./button";

const EventCard = (props) => (
    <section className={styles.eventCard}>
        <div className={styles.eventImageContent}>
            <div className={styles.dateTag}>
                <p className={styles.dateTagMonth}>{getShortDate(props.eventDate).month}</p>
                <p className={styles.dateTagDate}>{getShortDate(props.eventDate).date}</p>
            </div>
            {props.image && <img srcSet={props.image.srcSet} sizes="(min-width: 2048px) 2048px, (min-width: 1536px) 1536px, (min-width: 1024px) 1024px, (min-width: 768px) 768px, 100vw" alt={props.title} />}
        </div>
        <div className={`${styles.textContent}`}>
            <p className={styles.cardTitle}>{props.title}</p>
            <p className={styles.eventTimeLocation}>
                {`${getLongDate(props.eventDate).month} ${getLongDate(props.eventDate).date}, ${getLongDate(props.eventDate).year}`} <span className={styles.separator}>|</span> <span className={styles.eventTime}>{props.eventTime}</span> {props.eventTimezone} {props.locationName && <><span className={styles.separator}>|</span> {props.locationName}</> }
            </p>
            <div
                className={`${styles.paragraphText}`}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.content, {
                        allowedTags: ['div', 'h1', 'h2', 'h3', 'p', 'b', 'i'],
                    })
                }}></div>
            <div className={styles.bottomRow}>
                <CmsButton buttonText={props.buttonText} linkUrl={props.buttonLink} />
                <p className={styles.price}>{props.eventPrice}</p>
            </div>
        </div>
    </section>
)

const InsightCard = (props) => (
    <section className={styles.insightCard}>
        { props.image !== '' &&
        <div className={styles.insightImageContent}>
            <img srcSet={props.image.srcSet} sizes="(min-width: 2048px) 2048px, (min-width: 1536px) 1536px, (min-width: 1024px) 1024px, (min-width: 768px) 768px, 100vw" alt={props.title} />
        </div>
        }
        <div className={`${styles.textContent}  ${styles.insightTextContent}`}>
            <p className={styles.cardTitle}>{props.title}</p>

            <p className={styles.eventTimeLocation}>
                Created: {`${getLongDate(props.date).month} ${getLongDate(props.date).date}, ${getLongDate(props.date).year}`} 
            </p>

            <div
                className={`${styles.paragraphText}`}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.introCopy, {
                        allowedTags: ['div', 'h1', 'h2', 'h3', 'p', 'b', 'i'],
                    })
                }}></div>

            <div className={styles.bottomRow}>
                <CmsButton
                buttonText={props.buttonText}
                linkUrl= { props.insightUrl }
                />
            </div>

        </div>
    </section>
)

const NewsCard = (props) => (
    <section className={styles.newsCard}>
        {props.image && 
        <div className={styles.newsImageContent}>
            <img srcSet={props.image.srcSet} sizes="(min-width: 2048px) 2048px, (min-width: 1536px) 1536px, (min-width: 1024px) 1024px, (min-width: 768px) 768px, 100vw" alt={props.title} />
            <p className={styles.publishedDate}>Posted on: {props.date}</p>
        </div>
        }
        <div className={`${styles.textContent} ${styles.newsTextContent}`}>
            <p className={`${styles.cardTitle} ${styles.newsCardTitle}`}>{props.title}</p>
            <p className={styles.author}>{props.author}</p>
            <div
                className={`${styles.paragraphText} ${styles.newsParagraphText}`}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.content, {
                        allowedTags: ['div', 'h1', 'h2', 'h3', 'p', 'b', 'i'],
                    })
                }}></div>
            <div className={styles.bottomRowNews}>
                <CmsButton buttonText="Read More" linkUrl={props.buttonLink} newTab={props.newTab}/>
            </div>
        </div>
    </section>
)

const getLongDate = (date) => {

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const fullDate = new Date(Date.parse(date));
    let eventMonth = monthNames[fullDate.getMonth()];
    let eventDate = fullDate.getDate();
    let eventYear = fullDate.getFullYear();

    return {
        month: eventMonth,
        date: eventDate,
        ordinal: getOrdinal(eventDate),
        year: eventYear
    }
}

const getOrdinal = (date) => {
    
    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
}

const getShortDate = (date) => {

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const fullDate = new Date(Date.parse(date));

    let eventMonth = monthNames[fullDate.getMonth()];
    let eventDate = fullDate.getDate();

    return {
        date: eventDate,
        month: eventMonth
    };
}

export {EventCard, InsightCard, NewsCard, getShortDate, getLongDate, getOrdinal}
